import * as passwd from "./passwd";
import * as complexity_range from "./complexity-range";

function regenerate(rsg) {
  const newPasswd = rsg.genString($("#input-password-length").val());
  $("#password").val(newPasswd);
  $("#input-base64").val(passwd.toBase64(newPasswd));
}

/*
 *
 * ----------------------------------------------- */
jQuery(window).on("load", function () {
  const passwordLengthELe = $("#input-password-length");
  const complexityEle = $("#complexity-range-input");
  const defaultVal = 8;

  const rsg = new passwd.ASCIILevelsRSG(complexityEle.val());
  regenerate(rsg);

  $("#input-password-length-numletter").val(passwordLengthELe.val());

  $("#password").on("keyup", function () {
    const passwdEle = $(this);
    $("#input-base64").val(passwd.toBase64(passwdEle.val()));
  });

  $("#btn-refresh-password").on("click", () => {
    const rsg = new passwd.ASCIILevelsRSG(complexityEle.val());
    regenerate(rsg);
  });

  $(document).on("input change", "#complexity-range-input", function () {
    const rsg = new passwd.ASCIILevelsRSG(complexityEle.val());
    regenerate(rsg);
  });

  $(document).on("input change", "#input-password-length", function () {
    const rsg = new passwd.ASCIILevelsRSG(complexityEle.val());
    regenerate(rsg);
    $("#input-password-length-numletter").val($(this).val());
  });

  $(document).on(
    "input change",
    "#input-password-length-numletter",
    function () {
      const rsg = new passwd.ASCIILevelsRSG(complexityEle.val());
      regenerate(rsg);
      passwordLengthELe.val($(this).val());

      if (!$("#input-password-length-numletter").val()) {
        passwordLengthELe.val(defaultVal);
        regenerate(rsg);
      }
    }
  );
});

/*
 *
 * ----------------------------------------------- */
$("#input-password-length-numletter").on("keypress", function (e) {
  var currentValue = String.fromCharCode(e.which);
  var finalValue = $(this).val() + currentValue;
  if (finalValue > 32 || finalValue < 8) {
    e.preventDefault();
  }
});

/*
 *
 * ----------------------------------------------- */
jQuery(function ($) {
  $(window).on("load", function (e) {
    const bubble_range = $("#complexity-range-input");
    const bubble = $(".bubble-complexity-range");
    complexity_range.setBubble(bubble_range.get(0), bubble.get(0));
  });
});
