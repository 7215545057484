const allRanges = document.querySelectorAll(".container-all-complexity-range");
allRanges.forEach((wrap) => {
  const range = wrap.querySelector(".range-slider__range");
  const bubble = wrap.querySelector(".bubble");

  range.addEventListener("input", () => {
    setBubble(range, bubble);
  });
  setBubble(range, bubble);
});

export function setBubble(range, bubble) {
  const val = range.value;
  const min = range.min ? range.min : 0;
  const max = range.max ? range.max : 100;
  const newVal = Number(((val - min) * 100) / (max - min));
  let status;

  switch (newVal) {
    case 0:
      status = "Lower-case alpha characters (ab...) except i, l, o";
      break;
    case 20:
      status = "All previous, plus numbers (23...), except “0”, “1";
      break;
    case 40:
      status =
        "All previous, plus UPPER-case alpha characters (AB...), except 'O'";
      break;
    case 60:
      status = "All previous, plus the excluded easily-confused ones: il1o0O";
      break;
    case 80:
      status = "All previous, plus unambiguous symbols: @#$%&*+=?";
      break;
    case 100:
      status = "All previous, plus eyes-challenging symbols: `~^()<>-_!|\\/,.";
      break;
    default:
      status = "g";
  }

  bubble.innerHTML = status;

  // Sorta magic numbers based on size of the native UI thumb
  bubble.style.left = `calc(${newVal}% + (${8.5 - newVal * 0.3}px))`;
}
