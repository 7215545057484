import "./polyfill/number-is-nan";

import "bootstrap-sass";
import "jquery-match-height";

import "./modules/webfont";
import "./modules/jquery-match-height";
import "./modules/character-range";
import "./modules/select-text";
import "./modules/complexity-range";
import "./modules/passwd.entrypoint";
import "./modules/save-form-state";

// import "./modules/view-more";
// import "./modules/page-top";
// import "./modules/toggle-disabled-input-field";
// import "./modules/jqueryvalidation";
// import "./modules/menu-trigger";
// import "./modules/bs.tab";
// import "./modules/js-header";
// import "./modules/js-footer";
// import "./modules/waypoints";
// import "./modules/dropdown-hover";
// import "./modules/swiper";
// import "./modules/aos";
// import "./modules/luxy";
// import ScrollMagic from "scrollmagic";
